import React from 'react';
import { Helmet } from 'react-helmet';

const AdsenseScript = () => {
	return (
		<Helmet>
			<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6564995425259261"
				crossorigin="anonymous"></script>
		</Helmet>
	);
};

export default AdsenseScript;